<template>
  <div>
    <div v-if="isPagination" class="container vpagination">
      <div class="columns">
        <div class="column is-four-fifths">
          <span>Page </span>
          <button class="button is-small" :disabled="currentPage === 1" @click="prevPage()">
            <i class="fa fa-chevron-left" />
          </button>
          <span>
            {{ currentPage }} of {{ totalPages }} 
          </span>
          <button class="button is-small" :disabled="currentPage === totalPages" @click="nextPage()">
            <i class="fa fa-chevron-right" />
          </button>
          <span>| View </span>
          <div class="select">
            <select v-model="pageSize" @change="updateTable()">
              <option v-for="page in pages" :key="page">
                {{ page }}
              </option>
            </select>
          </div> 
          <span>records | Found total {{ filteredContent.length }} records</span>
          <span v-if="filteredContent.length !==content.length">
            (filtered from {{ content.length }} total entries)
          </span> 
        </div>
        <div class="column">
          <input ref="searchText" v-model="searchText" class="input" type="text">
        </div>
      </div>
    </div>
    <table class="vtable has-background-white">
      <thead>
        <tr>
          <th v-for="(col, colIndex) in header" 
              :key="colIndex" 
              :style="{ width: col.width ? col.width : 'auto' }" 
              :class="{'clickable pos-rel' :!(col.isSortable === false)}" 
              @click="sortTable(col)"
          >
            {{ col.value }}
            <span v-if="col.key == sortColumn" class="icon pos-abs r-0" :class="ascending ? 't-9' : 't-5'">
              <i class="fa" :class="ascending ? 'fa-sort-asc' : 'fa-sort-desc t-t'" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in getRows()" :key="rowIndex">
          <td v-for="(col, colIndex) in header" :key="colIndex">
            <slot v-if="col.custom" name="content" :data="{ row, col}" />
            <template v-else>
              <template v-if="col.onClick">
                <a v-if="mark" :inner-html.prop="row[col.key] | mark(searchText)" tabindex="0" @keyup.enter="col.onClick(row)" @click="col.onClick(row)" />
                <a v-else :inner-html.prop="row[col.key]" @click="col.onClick(row)" />  
              </template>
              <template v-else>
                <span v-if="mark && isPagination" :inner-html.prop="row[col.key] | mark(searchText)" />
                <span v-else-if="col.type && col.type === 'Date'">
                  {{ row[col.key] | formatDate() }}
                  <small v-if="col.secondaryItem" class="p-l-xs">
                    {{ row[col.secondaryItem] }}
                  </small>
                </span>
                <span v-else>
                  {{ row[col.key] }}
                  <small v-if="col.secondaryItem" class="p-l-xs">
                    <template v-if="col.secondaryItemType && col.secondaryItemType === 'DateTime'">
                      {{ row[col.secondaryItem] | formatDateTime() }}
                    </template>
                    <template v-else>
                      {{ row[col.secondaryItem] }}
                    </template>
                  </small>
                </span>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VTable',
  props: {
    header: {
      type: Array,
      required: true
    },
    content: {
      type: Array,
      required: true
    },
    isPagination: {
      type: Boolean,
      required: false,
      default: true
    },
    mark: {
      type: Boolean,
      required: false,
      default: true
    },
    elementsPerPage: {
      type: Number,
      required: false,
      default: 15
    },
    pages: {
      type: Array,
      required: false,
      default: () => [ 15, 50, 100, 'All' ]
    }
  },
  data () {
    return {
      currentPage: 1,
      // elementsPerPage: 15,
      // pages: [ 15, 50, 100 ]
      ascending: false,
      sortColumn: '',
      searchText: '',
      pageSize: this.elementsPerPage
    }
  },
  computed: {
    totalPages () {
      if (this.pageSize == 'All')
      {
        return 1
      }
      return Math.ceil(this.filteredContent.length / parseInt(this.pageSize))
    },
    filteredContent () {
      if (this.searchText.length > 0) {
        return this.content.filter((item) => {
          return Object.keys(item).some((key) => 
            item[key] !== null && 
            this.header.filter(h => h.key === key).length &&
            item[key].toString().toLowerCase().includes(this.searchText.toLowerCase()))
        })
      } else {
        return this.content
      }
    }
  },
  watch: {
    totalPages () {
      this.currentPage = 1
    }
  },
  mounted () {
    if (this.isPagination) {
      this.$refs.searchText.focus()
    }
  },
  methods: {
    sortTable (col) {
      this.$emit('sortTable')
      const isSortable = col.isSortable === false ? false : true
      if (isSortable) {
        if (this.sortColumn === col.key) {
          this.ascending = !this.ascending
        } else {
          this.ascending = true
          this.sortColumn = col.key
        }
        const ascending = this.ascending
        this.content.sort( (a, b) => {
          if ( a[col.key] > b[col.key] ) {
            return ascending ? 1 : -1
          } else if (a[col.key] < b[col.key]) {
            return ascending ? -1 : 1
          }
          return 0
        })
      }
    },
    numPages () {
      return Math.ceil(this.filteredContent.length / parseInt(this.pageSize))
    },
    getRows () {
      if (this.pageSize == 'All')
      {
        const start = (this.currentPage-1) * parseInt(this.pageSize)
        const end = parseInt(this.filteredContent.length)
        this.totalPages = 1
        return this.filteredContent.slice(start, end)
      }
      const start = (this.currentPage-1) * parseInt(this.pageSize)
      const end = start + parseInt(this.pageSize)
      return this.filteredContent.slice(start, end)
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1 
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage = this.currentPage + 1 
      }
    },

    updateTable () {
      this.getRows()
      this.numPages()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>