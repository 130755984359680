import { BaseResolver } from './BaseResolver'

export class WaterMeterDetailResolver extends BaseResolver {
  constructor () {
    super('waterMeterDetail')
  }

  resolve (item) {        
    return {
      WaterDetailId: item.WaterDetailId,
      AccountId: item.AccountId, 
      Siteid: item.SiteId,    
      AccountReference: item.WaterAccountInfo.AccountReference,
      DetailRef:item.WaterAccountInfo.DetailRef,
      AccountAlias: item.WaterAccountInfo.Accountalias,
      AccountStatus: item.WaterAccountInfo.AccountStatus,
      ActiveFrom: item.WaterAccountInfo.ActiveFrom,
      MeasureType: item.MeasureType,
      SurfaceDrain: item.SurfaceDrain,
      HighwayDrain: item.HighwayDrain,
      IsSewerageTariffSingle: item.IsSewerageTariffSingle,
      ConnectionType: item.ConnectionType,
      WaterMeters: (item.WaterMeters || []).map(wm => {
        return {
          WaterDetailId:wm.WaterDetailId,
          WaterMeterId: wm.WaterMeterId,
          MSN: wm.MSN,
          Unit: wm.UnitofMeasure,
          Status: wm.Status,
          MeterLocation: wm.MeterLocation,
          StartDate: wm.StartDate,
          WaterSPID: wm.WaterSPID,
          SewerageSPID: wm.SewerageSPID,
          StatusHistory: wm.StatusHistory,
          AccountReference: item.WaterAccountInfo.AccountReference,
          AccountAlias: item.WaterAccountInfo.Accountalias,
          AccountStatus: item.WaterAccountInfo.AccountStatus,        
          DetailRef:item.WaterAccountInfo.DetailRef,
          ActiveFrom: item.WaterAccountInfo.ActiveFrom,
          InActiveFrom: item.WaterAccountInfo.InActiveFrom,
          MeterMake: wm.MeterMake,
          MeterSize: wm.MeterSize,
          PropertySize: wm.PropertySize,
          MeasureType: wm.MeasureType,
          SurfaceDrain: wm.SurfaceDrain,
          HighwayDrain: wm.HighwayDrain,
          ConnectionType: item.ConnectionType,
          IsSewerageTariffSingle: wm.IsSewerageTariffSingle,
          SeweragePercentage:wm.SeweragePercentage,
          EAC: item.EAC,
          EACList: item.EACList || [],
          SOQ: item.SOQ,
          SOQList: item.SOQList || [],
          Rateable: item.Rateable,
          RateableList: item.RateableList || [],
          VATPercentage: item.VATPercentage,
          VATExemptionList: item.VATExemptionList || [],
          BillValidationHold:wm.BillValidationHold,
        }
      })
      
    }
  }
}
