<template>
  <div>
    <div class="container vpagination">
      <div class="container">
        <slot />
      </div>
      <div class="columns">
        <div class="column is-four-fifths">
          <template v-if="totalRecords > elementsPerPage">
            <span>Page </span>
            <button class="button is-small" :disabled="currentPage < 2" @click="$emit('prevPage')">
              <i class="fa fa-chevron-left" />
            </button>
            <span>
              {{ currentPage }} of {{ totalPages }} 
            </span>
            <button class="button is-small" :disabled="currentPage === totalPages" @click="$emit('nextPage')">
              <i class="fa fa-chevron-right" />
            </button>
            <span>| View </span>
            <div class="select">
              <select v-model="pageSize" @change="$emit('pageSizeChange', pageSize)">
                <option>20</option>
                <option>50</option>
                <option>100</option>
                <option>All</option>
              </select>
            </div> 
            <span>records | </span>
          </template>
          <span>Found total {{ filteredRecords }} records</span>
          <span v-if="filteredRecords !== totalRecords">
            (filtered from {{ totalRecords }} total entries)
          </span> 
        </div>
        <div class="column" />
      </div>
    </div>
    <table class="vtable has-background-white">
      <thead>
        <tr>
          <th v-for="(col, colIndex) in header" 
              :key="colIndex" 
              :style="{ width: col.width ? col.width + 'px' : '' }" 
              :class="{'clickable pos-rel' :!(col.isSortable === false)}" 
              @click="$emit('sortTable', col, colIndex)"
          >
            {{ col.value }}
            <span v-if="col.key == sortColumn" class="icon pos-abs r-0" :class="direction ? 't-9' : 't-5'">
              <i class="fa" :class="direction ? 'fa-sort-asc' : 'fa-sort-desc t-t'" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in content" :key="rowIndex">
          <td v-for="(col, colIndex) in header" :key="colIndex">
            <slot v-if="col.custom" name="content" :data="{ row, col}" />
            <template v-else>
              <a v-if="col.onClick" tabindex="0" @keyup.enter="col.onClick(row)" @click="col.onClick(row)">
                {{ row[col.key] }}
              </a>
              <span v-else>
                {{ row[col.key] }}
              </span>
            </template>
          </td>
        </tr>
        <tr v-if="totalRecords === 0">
          <td :colspan="header.length">
            No records found!
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'VAjaxTable',
  props: {
    header: {
      type: Array,
      required: true
    },
    content: {
      type: Array,
      required: true
    },
    totalRecords: {
      type: Number,
      required: true
    },
    filteredRecords: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    elementsPerPage: {
      type: Number,
      required: true
    },
    direction: {
      type: Boolean,
      required: true
    },
    sortColumn: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      pageSize: this.elementsPerPage
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.filteredRecords / this.elementsPerPage)
    }
  }
}
</script>
