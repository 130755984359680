import { BaseResolver } from './BaseResolver'

export class GasDetailResolver extends BaseResolver {
  constructor () {
    super('gasDetail')
  }

  resolve (item) {
    return {
      Id: item.Id,
      AccountId: item.AccountId, 
      SiteId: item.SiteId,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      tabIndex: 1,
      TariffExists: item.TariffExists,
      BillExists: item.BillExists,
      BillExistsDate: item.BillExistsDate,
      GasMeters: (item.GasMeters || []).map(meter => {
        return {
          Id: meter.Id,
          MSN: meter.MSN,
          Status: meter.Status,
          StartDate: meter.StartDate,
          EndDate: meter.EndDate,          
          Meterref: meter.Meterref,
          ExpenseCode: meter.ExpenseCode,
          KeyRequired: meter.KeyRequired,
          BillValidationHold: meter.BillValidationHold,
          LocationOf: meter.LocationOf,
          MeterUsageArea: meter.MeterUsageArea,
          SpecialRequirement: meter.SpecialRequirement,
          Mprn: meter.Mprn,
          SettlementClass: meter.SettlementClass,
          CreatedBy: meter.CreatedBy,
          CreatedDate: meter.CreatedDate,
          UpdatedBy: meter.UpdatedBy,
          UpdatedDate: meter.UpdatedDate,
          LicensedGT: meter.LicensedGT,
          StatusHistory: meter.StatusHistory,
          TariffExists: item.TariffExists,
          BillExists: item.BillExists,
          BillExistsDate: item.BillExistsDate,
          BillExistsFromDate: item.BillExistsFromDate,
          GasDetailId:meter.GasDetailId,
          EAC: meter.EAC,
          EACList: meter.EACList || [],
          SOQ: item.SOQ,
          SOQList: item.SOQList || [],
          ExpectedComm: item.ExpectedComm,
          ExpectedCommList: item.ExpectedCommList || [],
          CCLPercentage: item.CCLPercentage,
          CCLExemptionList: item.CCLExemptionList || [],
          VATPercentage: meter.VATPercentage,
          VATExemptionList: meter.VATExemptionList || [],
          Unit: meter.Unit,
          GasUnitList: meter.GasUnitList || [],
          GasMeterType:item.GasMeterType == 0 ? null : item.GasMeterType, 
          SupplyType:item.SupplyType == 0 ? null : item.SupplyType, 
          TransporterType:item.TransporterType == 0 ? null : item.TransporterType, 
          LDZExitZone:item.LDZExitZone == 0 ? null : item.LDZExitZone, 
          //ponni:placeholder created with meterref need to change after api implementation
          AccountReference:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].AccountReference,
          AccountAlias:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].Accountalias,
          DetailRef:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].DetailRef,
          AccountStatus:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].AccountStatus,
          ActiveFrom:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].ActiveFrom,
          InActiveFrom:item.GasAccountInfo.length == 0 ? null : item.GasAccountInfo[0].InActiveFrom,
        }
      })
    }
  }
}
