// export const google_map_api_key = 'AIzaSyDtxM5_Q2nxUuiRg1uGBEOgDWGPoWb3Iik'

export const constants = {

  VATType: [
    { Id: 'DOM', Name: 'Domestic' },
    { Id: 'COM', Name: 'Commercial' }
  ],

  Scheme: [
    { Id: 'Office', Name: 'Office' },
    { Id: 'Rented', Name: 'Rented' },
    { Id: 'AssistedLiving', Name: 'Assisted Living' },
    { Id: 'Extra Care', Name: 'Extra Care' }
  ],

  AreaType: [ 
    { Id: 'Sq.Feet', Name: 'Sq.Feet' },
    { Id: 'Sq.Meter', Name: 'Sq.Meter' }
  ],

  meterStatus : [
    { Id: 1, Name: 'Energised', Code: 'Energised' },
    { Id: 2, Name: 'De-Energised', Code: 'DeEnergised' },
    { Id: 3, Name: 'Disconnected', Code: 'Disconnected' },
    { Id: 4, Name: 'Exchanged', Code: 'Exchanged' }
  ],

  meterLocation: [
    { Id: '1', Name: 'Cellar' },
    { Id: '2', Name: 'Under Stairs' },
    { Id: '3', Name: 'Hall' },
    { Id: '4', Name: 'Kitchen' },
    { Id: '5', Name: 'Bathroom' },
    { Id: '6', Name: 'Unknown' }
  ],
  oppLeadSource: [
    { Id: '1', Name: 'Advertisement' },
    { Id: '2', Name: 'Employee Referral' },
    { Id: '3', Name: 'External Referral' },
    { Id: '4', Name: 'Partner' },
    { Id: '5', Name: 'Public Relations' },
    { Id: '6', Name: 'Seminar Internal' },
    { Id: '7', Name: 'Seminar Partner' },
    { Id: '8', Name: 'TradeShow' },
    { Id: '9', Name: 'Web' },
    { Id: '10', Name: 'Word of Mouth' },
    { Id: '11', Name: 'Other' }
  ],
  oppLostReason: [
    { Id: '1', Name: 'Abandoned Registration' },
    { Id: '2', Name: 'Customer signed direct with supplier' },
    { Id: '3', Name: 'No response from customer' },
    { Id: '4', Name: 'Perceived inconvenience of changing' },
    { Id: '5', Name: 'Poor customer service/trust from Monarch' },
    { Id: '6', Name: 'Price' },
    { Id: '7', Name: 'Signed vis another broker' },
    { Id: '8', Name: 'Sites Sold' },
    { Id: '9', Name: 'Sites tendered on another CNB' },
    { Id: '10', Name: 'Test CNB' }
  ],
  oppSubsidary: [
    { Id: '1', Name: 'Monarch' },
    { Id: '2', Name: 'MidMarket' },
    { Id: '3', Name: 'ESS' },
    { Id: '4', Name: 'EIC Flex' },
    { Id: '5', Name: 'EIC Fixed' }
  ],
  oppCommission: [
    { Id: '1', Name: 'Unit Uplift' },
    { Id: '2', Name: 'Flat Fee' },
    { Id: '3', Name: 'Meter Uplift' }
  ],
  oppUtility: [
    { Id: '1', Name: 'Elec - HH' },
    { Id: '2', Name: 'Elec - NHH' },
    { Id: '3', Name: 'Gas' },
    { Id: '4', Name: 'Water' }
  ],
  oppStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Tender' },
    { Id: '4', Name: 'Proposal' },
    { Id: '5', Name: 'Action' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  amrStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Survey' },
    { Id: '4', Name: 'Proposal' },
    { Id: '5', Name: 'Negotition' },    
    { Id: '6', Name: 'Action' },
    { Id: '7', Name: 'Closed Won' },
    { Id: '8', Name: 'Closed Lost' }
  ],
  bdmStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Proposal' },
    { Id: '4', Name: 'Action' },
    { Id: '5', Name: 'Closed Won' },    
    { Id: '6', Name: 'Closed Lost' }
  ],
  carbonStage: [
    { Id: '1', Name: 'ID Decision Maker' },
    { Id: '2', Name: 'Prepare' },
    { Id: '3', Name: 'Proposal' },
    { Id: '4', Name: 'Closed Won' },    
    { Id: '5', Name: 'Closed Lost' }
  ],
  decStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Tender' },
    { Id: '4', Name: 'Proposal' },    
    { Id: '5', Name: 'Action' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  esosStage: [
    { Id: '1', Name: 'Qualification' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Proposal' },
    { Id: '4', Name: 'Closed Won' },    
    { Id: '5', Name: 'Closed Lost' }
  ],
  eduStage: [
    { Id: '1', Name: 'Qualification' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Need Analysis' },
    { Id: '4', Name: 'Proposal' },    
    { Id: '5', Name: 'Negotiation' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  midMarketStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Tender' },
    { Id: '4', Name: 'Proposal' },    
    { Id: '5', Name: 'Action' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  businessStage: [
    { Id: '1', Name: 'Qualification' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Needs Analysis' },
    { Id: '4', Name: 'Proposal' },    
    { Id: '5', Name: 'Negotiation' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  sdmpStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Approval' },
    { Id: '4', Name: 'Action' },
    { Id: '5', Name: 'Closed Won' },    
    { Id: '6', Name: 'Closed Lost' }
  ],
  secrStage: [
    { Id: '1', Name: 'Qualification' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Proposal' },
    { Id: '4', Name: 'Closed Won' },    
    { Id: '5', Name: 'Closed Lost' }
  ],
  tenantStage: [
    { Id: '1', Name: 'Prepare' },
    { Id: '2', Name: 'ID Decision Maker' },
    { Id: '3', Name: 'Proposal' },
    { Id: '4', Name: 'In Progress' },    
    { Id: '5', Name: 'Contract Out' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  esosDeliveryStage: [
    { Id: '1', Name: 'ID Decision Maker' },
    { Id: '2', Name: 'Define Team' },
    { Id: '3', Name: 'Prepare' },
    { Id: '4', Name: 'Initial Data Collection and Audit' },    
    { Id: '5', Name: 'Compliance Year Data Collection and Audit' },
    { Id: '6', Name: 'Closed Won' },
    { Id: '7', Name: 'Closed Lost' }
  ],
  carbonService: [
    { Id: '1', Name: 'EPC' },
    { Id: '2', Name: 'ACI' },
    { Id: '3', Name: 'Carbon Foot Print' },
    { Id: '4', Name: 'Carbon Management Plan' },    
    { Id: '5', Name: 'Carbon Offsetting' },
    { Id: '6', Name: 'CCA' },
    { Id: '7', Name: 'DEC' },
    { Id: '8', Name: 'Embedded Energy Manager' },
    { Id: '9', Name: 'Meter Hosting' },
    { Id: '10', Name: 'MinMet' },
    { Id: '11', Name: 'PAS 2060' },
    { Id: '12', Name: 'Scope 3 Review' },
    { Id: '13', Name: 'TCFD' },
    { Id: '14', Name: 'UK ETS' },
    { Id: '15', Name: 'Energy Survey' },
    { Id: '16', Name: 'EPDB' }
  ],
  decService: [
    { Id: '1', Name: 'DEC' },
    { Id: '2', Name: 'EPC' }
  ],
  esosService: [
    { Id: '1', Name: 'Accrediation' },
    { Id: '2', Name: 'Review' },
    { Id: '3', Name: 'Full Compliance' },
    { Id: '4', Name: 'Audit Only' }
  ],
  esosCompliance: [
    { Id: '1', Name: 'Full Compliance' },
    { Id: '2', Name: 'Option 2' },
    { Id: '3', Name: 'Option 3' }
  ],
  esosCurrentProvider: [
    { Id: '1', Name: 'CCS' },
    { Id: '2', Name: 'Inenco' },
    { Id: '3', Name: 'Inprova' }
  ],
  esosAuditData: [
    { Id: '1', Name: 'Requested' },
    { Id: '2', Name: 'In Progress' },
    { Id: '3', Name: 'Complete' }
  ],
  esosSEURpt: [
    { Id: '1', Name: 'Not Started' },
    { Id: '2', Name: 'In Progress' },
    { Id: '3', Name: 'Complete' }
  ],
  esosFinalRpt: [
    { Id: '1', Name: 'Not Started' },
    { Id: '2', Name: 'In Progress' },
    { Id: '3', Name: 'QA' },
    { Id: '4', Name: 'Awaiting Client Approval' },
    { Id: '5', Name: 'Signed By Client' },
    { Id: '6', Name: 'Submitted To EA' }
  ],
  esosSEUTool: [
    { Id: '1', Name: 'Not Started' },
    { Id: '2', Name: 'Requested' },
    { Id: '3', Name: 'SECR Data Available SCER Not Started' },
    { Id: '4', Name: 'Ph2 Transport > 5% Send Questionnaire' },
    { Id: '5', Name: 'Data 10%' },
    { Id: '6', Name: 'Data 25%' },
    { Id: '7', Name: 'Data 50%' },
    { Id: '8', Name: 'Data 75%' },
    { Id: '9', Name: 'Transport > 5% Questionnaire' },
    { Id: '10', Name: 'Data 95%' },
    { Id: '11', Name: 'Analyst Check' },
    { Id: '12', Name: 'Analyst Team Check' },
    { Id: '13', Name: 'LA QA' },
    { Id: '14', Name: 'Complete' }
  ],
  secrServiceYears: [
    { Id: '1', Name: '1' },
    { Id: '2', Name: '2' },
    { Id: '3', Name: '3' },
    { Id: '4', Name: '4' },
    { Id: '5', Name: '5' }
  ],
  secrOtherServices: [
    { Id: '1', Name: 'Carbon' },
    { Id: '2', Name: 'Multiplied' },
    { Id: '3', Name: 'Monarch' }
  ],
  secrServiceLevel: [
    { Id: '1', Name: 'Light' },
    { Id: '2', Name: 'Standard' },
    { Id: '3', Name: 'Premium' },
    { Id: '4', Name: 'Bespoke' },
    { Id: '5', Name: '3 Years(Monarch)' }
  ],
  secrReportingIntervals: [
    { Id: '1', Name: 'Quarterly' },
    { Id: '2', Name: 'Bi-Annual' },
    { Id: '3', Name: 'Annual' }
  ],
  tenantBuildings:[
    { Id: '1', Name: 'Residential' },
    { Id: '2', Name: 'Commercial' },
    { Id: '3', Name: 'Education' },
    { Id: '4', Name: 'Healthcare' }
  ],
  tenantSetupFeeBilled:[
    { Id: '1', Name: 'Residential' },
    { Id: '2', Name: 'Commercial' },
    { Id: '3', Name: 'Education' },
    { Id: '4', Name: 'Healthcare' }
  ],
  tenantContractInvCycle: [
    { Id: '1', Name: 'Monthly' },
    { Id: '2', Name: 'Quarterly' },
    { Id: '3', Name: 'Annually' }
  ],
  tenantSiteBillingCycle: [
    { Id: '1', Name: 'Monthly' },
    { Id: '2', Name: 'Quarterly' },
    { Id: '3', Name: 'Bi-Annually' },
    { Id: '4', Name: 'Annually' }
  ],
  tenantContractInvType: [
    { Id: '1', Name: 'Client Directed' },
    { Id: '2', Name: 'Collected Funds' }
  ],
  tenantSiteBillingType: [
    { Id: '1', Name: 'Option 1' },
    { Id: '2', Name: 'Option 2' },
    { Id: '3', Name: 'Option 3' },
    { Id: '4', Name: 'Option 4' }
  ],
  oppType: [
    { Id: '1', Name: 'Contract Renewal' },
    { Id: '2', Name: 'AMR' },
    { Id: '3', Name: 'BDM Contract Renewals' },
    { Id: '4', Name: 'Carbon Services' },
    { Id: '5', Name: 'DEC-EPC' },
    { Id: '6', Name: 'ESOS' },
    { Id: '7', Name: 'Education Opportunity' },
    { Id: '8', Name: 'MOP/DC/DA Renewal' },
    { Id: '9', Name: 'Mid Market' },
    { Id: '10', Name: 'New Business Opportunity' },
    { Id: '11', Name: 'Planet Mark' },
    { Id: '12', Name: 'SDMP' },
    { Id: '13', Name: 'SECR Sales Opp' },
    { Id: '14', Name: 'Tenant Billing' },
    { Id: '15', Name: 'Waste' },
    { Id: '16', Name: 'T-Mac OEM' },
    { Id: '17', Name: 'T-Mac Hosting' },
    { Id: '18', Name: 'T-Mac Sales' },
    { Id: '19', Name: 'T-Mac Sales(HW)' },
    { Id: '20', Name: 'ESOS Delivery' }
  ],
  accType: [
    { Id: '1', Name: 'Customer and Prospect' },
    { Id: '2', Name: 'EIC and T-Mac' },
    { Id: '3', Name: 'Suppliers(Others)' },
    { Id: '4', Name: 'Utility Supplier Contact' },
    { Id: '5', Name: 'Competitor' },
    { Id: '6', Name: 'GNE' }
  ],
  CPcustType: [
    { Id: '1', Name: 'Customer' },
    { Id: '2', Name: 'Other' },
    { Id: '3', Name: 'Partner' },
    { Id: '4', Name: 'Planet Mark Referral' },
    { Id: '5', Name: 'Prospect' },
    { Id: '6', Name: 'Supplier' }
  ],
  EICcustType: [
    { Id: '1', Name: 'Client - EIC' },
    { Id: '2', Name: 'Client - TMac' },
    { Id: '3', Name: 'Prospect - EIC' },
    { Id: '4', Name: 'Closed Down' }
  ],
  CompcustType: [
    { Id: '1', Name: 'Competitor' },
    { Id: '2', Name: 'Planet Mark Referral' }
  ],
  UtilSupcustType: [
    { Id: '1', Name: 'Supplier' },
    { Id: '2', Name: 'Planet Mark Referral' }
  ],
  GNEFinalScore: [
    { Id: '1', Name: 'OK' },
    { Id: '2', Name: 'Pending' }
  ],
  GNECheckedStatus: [
    { Id: '1', Name: 'In Progress' },
    { Id: '2', Name: 'Objected' },
    { Id: '3', Name: 'Rejection' },
    { Id: '4', Name: 'In Service' },
    { Id: '5', Name: 'Failed Credit' }
  ],
  establishmentType: [
    { Id: '1', Name: 'Academics' },
    { Id: '2', Name: 'College' },
    { Id: '3', Name: 'Free Schools' },
    { Id: '4', Name: 'Independent Schools' },
    { Id: '5', Name: 'LA Maintained Schools' },
    { Id: '6', Name: 'Other Types' },
    { Id: '7', Name: 'Special Schools' },
    { Id: '8', Name: 'Universities' }
  ],
  localAuthority: [
    { Id: '1', Name: 'Barking and Dagenham' },
    { Id: '2', Name: 'Barnet' },
    { Id: '3', Name: 'Barnsley' },
    { Id: '4', Name: 'Bath and North East Somerset' },
    { Id: '5', Name: 'Bedford' },
    { Id: '6', Name: 'Bexley' },
    { Id: '7', Name: 'Birmingham' },
    { Id: '8', Name: 'Blackburn with Darwen' },   
    { Id: '9', Name: 'Blackpool' },
    { Id: '10', Name: 'Bolton' },
    { Id: '11', Name: 'Bournemouth' },
    { Id: '12', Name: 'Bracknell Forest' },
    { Id: '13', Name: 'Bradford' },
    { Id: '14', Name: 'Brent' },
    { Id: '15', Name: 'Brighton and Hove' },
    { Id: '16', Name: 'Bristol City of' },
    { Id: '17', Name: 'Bromley' },
    { Id: '18', Name: 'Buckinghamshire' },
    { Id: '19', Name: 'Bury' },
    { Id: '20', Name: 'Calderdale' },
    { Id: '21', Name: 'Cambridgeshire' },
    { Id: '22', Name: 'Camden' },
    { Id: '23', Name: 'Central Bedfordshire' },
    { Id: '24', Name: 'Cheshire East' },
    { Id: '25', Name: 'Cheshire West and Chester' },
    { Id: '26', Name: 'City of London' },
    { Id: '27', Name: 'Cornwall' },
    { Id: '28', Name: 'Coventry' },
    { Id: '29', Name: 'Croydon' },
    { Id: '30', Name: 'Cumbria' },
    { Id: '31', Name: 'Darlington' },
    { Id: '32', Name: 'Derby' },
    { Id: '33', Name: 'Derbyshire' },
    { Id: '34', Name: 'Devon' },
    { Id: '35', Name: 'Doncaster' },
    { Id: '36', Name: 'Dorset' },
    { Id: '37', Name: 'Dudley' },
    { Id: '38', Name: 'Durham' },
    { Id: '39', Name: 'Ealing' },
    { Id: '40', Name: 'East Riding of Yorkshire' },
    { Id: '41', Name: 'East Sussex' },
    { Id: '42', Name: 'Enfield' },
    { Id: '43', Name: 'Essex' },
    { Id: '44', Name: 'Gateshead' },
    { Id: '45', Name: 'Gloucestershire' },
    { Id: '46', Name: 'Greenwich' },
    { Id: '47', Name: 'Hackney' },
    { Id: '48', Name: 'Halton' },
    { Id: '49', Name: 'Hammersmith and Fulham' },
    { Id: '50', Name: 'Hampshire' },
    { Id: '51', Name: 'Haringey' },
    { Id: '52', Name: 'Harrow' },
    { Id: '53', Name: 'Hartlepool' },
    { Id: '54', Name: 'Havering' },
    { Id: '55', Name: 'Herefordshire' },
    { Id: '56', Name: 'Hillingdon' },
    { Id: '57', Name: 'Hounslow' },
    { Id: '58', Name: 'Isle of Wight' },
    { Id: '59', Name: 'Isles Of Scilly' },
    { Id: '60', Name: 'Islington' },
    { Id: '61', Name: 'Kensington and Chelsea' },
    { Id: '62', Name: 'Kent' },
    { Id: '63', Name: 'Kingston upon Hull City of' },
    { Id: '64', Name: 'Kingston upon Thames' },
    { Id: '65', Name: 'Kirklees' },
    { Id: '66', Name: 'Knowsley' },
    { Id: '67', Name: 'Lambeth' },
    { Id: '68', Name: 'Lancashire' },
    { Id: '69', Name: 'Leeds' },
    { Id: '70', Name: 'Leicester' },
    { Id: '71', Name: 'Leicestershire' },
    { Id: '72', Name: 'Lewisham' },
    { Id: '73', Name: 'Lincolnshire' },
    { Id: '74', Name: 'Liverpool' },
    { Id: '75', Name: 'Luton' },
    { Id: '76', Name: 'Manchester' },
    { Id: '77', Name: 'Medway' },
    { Id: '78', Name: 'Merton' },
    { Id: '79', Name: 'Middlesbrough' },
    { Id: '80', Name: 'Milton Keynes' },
    { Id: '81', Name: 'Newcastle upon Tyne' },
    { Id: '82', Name: 'Newham' },
    { Id: '83', Name: 'Norfolk' },
    { Id: '84', Name: 'Northamptonshire' },
    { Id: '85', Name: 'North East Lincolnshire' },
    { Id: '86', Name: 'North Lincolnshire' },
    { Id: '87', Name: 'North Somerset' },
    { Id: '88', Name: 'North Tyneside' },
    { Id: '89', Name: 'Northumberland' },
    { Id: '90', Name: 'North Yorkshire' },
    { Id: '91', Name: 'Nottingham' },
    { Id: '92', Name: 'Nottinghamshire' },
    { Id: '93', Name: 'Oldham' },
    { Id: '94', Name: 'Oxfordshire' },
    { Id: '95', Name: 'Peterborough' },
    { Id: '96', Name: 'Plymouth' },
    { Id: '97', Name: 'Poole' },
    { Id: '98', Name: 'Portsmouth' },
    { Id: '99', Name: 'Reading' },
    { Id: '100', Name: 'Redbridge' },
    { Id: '101', Name: 'Redcar and Cleveland' },
    { Id: '102', Name: 'Richmond upon Thames' },
    { Id: '103', Name: 'Rochdale' },
    { Id: '104', Name: 'Rotherham' },
    { Id: '105', Name: 'Rutland' },
    { Id: '106', Name: 'Salford' },
    { Id: '107', Name: 'Sandwell' },
    { Id: '108', Name: 'Sefton' },
    { Id: '109', Name: 'Sheffield' },
    { Id: '110', Name: 'Shropshire' },
    { Id: '111', Name: 'Slough' },
    { Id: '112', Name: 'Solihull' },
    { Id: '113', Name: 'Somerset' },
    { Id: '114', Name: 'Southampton' },
    { Id: '115', Name: 'Southend-on-Sea' },
    { Id: '116', Name: 'South Gloucestershire' },
    { Id: '117', Name: 'South Tyneside' },
    { Id: '118', Name: 'Southwark' },
    { Id: '119', Name: 'St. Helens' },
    { Id: '120', Name: 'Staffordshire' },
    { Id: '121', Name: 'Stockport' },
    { Id: '122', Name: 'Stockton-on-Tees' },
    { Id: '123', Name: 'Stoke-on-Trent' },
    { Id: '124', Name: 'Suffolk' },
    { Id: '125', Name: 'Sunderland' },
    { Id: '126', Name: 'Surrey' },
    { Id: '127', Name: 'Sutton' },
    { Id: '128', Name: 'Swindon' },
    { Id: '129', Name: 'Tameside' },
    { Id: '130', Name: 'Telford and Wrekin' },
    { Id: '131', Name: 'Thurrock' },
    { Id: '132', Name: 'Torbay' },
    { Id: '133', Name: 'Tower Hamlets' },
    { Id: '134', Name: 'Trafford' },
    { Id: '135', Name: 'Wakefield' },
    { Id: '136', Name: 'Walsall' },
    { Id: '137', Name: 'Waltham Forest' },
    { Id: '138', Name: 'Wandsworth' },
    { Id: '139', Name: 'Warrington' },
    { Id: '140', Name: 'Warwickshire' },
    { Id: '141', Name: 'West Berkshire' },
    { Id: '142', Name: 'Westminster' },
    { Id: '143', Name: 'West Sussex' },
    { Id: '144', Name: 'Wigan' },
    { Id: '145', Name: 'Wiltshire' },
    { Id: '146', Name: 'Windsor and Maidenhead' },
    { Id: '147', Name: 'Wirral' },
    { Id: '148', Name: 'Wokingham' },
    { Id: '149', Name: 'Wolverhampton' },
    { Id: '150', Name: 'Worcestershire' },
    { Id: '151', Name: 'York' }
  ],
  subType: [
    { Id: '1', Name: 'Dominos Franchise' },
    { Id: '2', Name: 'Energy Services Client' },
    { Id: '3', Name: 'Mid Market Agreement' },
    { Id: '4', Name: 'Mid Market LOA' },
    { Id: '5', Name: 'Non Procurement Client' },
    { Id: '6', Name: 'Procurement Client' },
    { Id: '7', Name: 'Tier 1' },
    { Id: '8', Name: 'Tier 2' },   
    { Id: '9', Name: 'Tier 3' },
    { Id: '10', Name: 'Tier 4' }
  ],
  businessType: [
    { Id: '1', Name: 'Chartity' },
    { Id: '2', Name: 'LLP' },
    { Id: '3', Name: 'Ltd' },
    { Id: '4', Name: 'Partnership' },
    { Id: '5', Name: 'Social Club' },
    { Id: '6', Name: 'Sole Trader' }
  ],
  fuelType: [
    { Id: '1', Name: 'Elec' },
    { Id: '2', Name: 'Elec-Green' },
    { Id: '3', Name: 'Gas' }
  ],
  outcome: [
    { Id: '1', Name: 'Accepted' },
    { Id: '2', Name: 'Not Accepted' },
    { Id: '3', Name: 'Rejected' }
  ],
  accSource: [
    { Id: '1', Name: 'Advertisement' },
    { Id: '2', Name: 'Employee Referral' },
    { Id: '3', Name: 'External Referral' },
    { Id: '4', Name: 'Partner' },
    { Id: '5', Name: 'Public Relations' },
    { Id: '6', Name: 'Seminar Internal' },
    { Id: '7', Name: 'Seminar Partner' },
    { Id: '8', Name: 'TradeShow' },
    { Id: '9', Name: 'Web' },
    { Id: '10', Name: 'Word of Mouth' },
    { Id: '11', Name: 'Other' },
    { Id: '12', Name: 'Purchased List' },
    { Id: '13', Name: 'Academics Show 2018 ExCel London ' }
  ],
  accSharing: [
    { Id: '1', Name: 'EIC & Welcome' },
    { Id: '2', Name: 'EIC and Monarch' },
    { Id: '3', Name: 'EIC and ESS' },
    { Id: '4', Name: 'Monarch & Welcome' },
    { Id: '5', Name: 'Monarch & ESS' },
    { Id: '6', Name: 'ESS & Welcome' },
    { Id: '7', Name: 'ESS & Monarch' },
    { Id: '8', Name: 'EIC' },
    { Id: '9', Name: 'ESS' },
    { Id: '10', Name: 'Welcome' },
    { Id: '11', Name: 'Monarch' }
  ],
  accIndustry: [
    { Id: '1', Name: 'Academy' },
    { Id: '2', Name: 'Academy - Multi Trust' },
    { Id: '3', Name: 'Agriculture' },
    { Id: '4', Name: 'Apparel' },
    { Id: '5', Name: 'Automotive' },
    { Id: '6', Name: 'Banking' },
    { Id: '7', Name: 'Biotechnology' },
    { Id: '8', Name: 'Cable Manufacturers' },
    { Id: '9', Name: 'Ceramic' },
    { Id: '10', Name: 'Charity' },
    { Id: '11', Name: 'Chemicals' },
    { Id: '12', Name: 'College' },
    { Id: '13', Name: 'Communications' },
    { Id: '14', Name: 'Council/Local Authority' },
    { Id: '15', Name: 'Construction' },	
    { Id: '16', Name: 'Construction Engineering' },
    { Id: '17', Name: 'Consulting' },
    { Id: '18', Name: 'Distribution/Wholesale' },
    { Id: '19', Name: 'Education' },
    { Id: '20', Name: 'Electrical and Electronics' },
    { Id: '21', Name: 'Electronics' },
    { Id: '22', Name: 'Energy' },
    { Id: '23', Name: 'Engineering' },
    { Id: '24', Name: 'Estate Management' },
    { Id: '25', Name: 'Entertainment' },
    { Id: '26', Name: 'Environmental' },
    { Id: '27', Name: 'Facility Management' },
    { Id: '28', Name: 'Finance' },	
    { Id: '29', Name: 'Finance and Law' },
    { Id: '30', Name: 'Food & Beverage' },
    { Id: '31', Name: 'Franchise' },
    { Id: '32', Name: 'Furniture, Garden and Home' },
    { Id: '33', Name: 'Government' },
    { Id: '34', Name: 'Government/Public Sector' },
    { Id: '35', Name: 'Healthcare' },
    { Id: '36', Name: 'Historic Houses' },
    { Id: '37', Name: 'HoReCa or Hospitality' },
    { Id: '38', Name: 'Housing Association' },
    { Id: '39', Name: 'Independent' },
    { Id: '40', Name: 'Large Office Units' },
    { Id: '41', Name: 'Leisure' },
    { Id: '42', Name: 'Machinery' },
    { Id: '43', Name: 'Manufacturing' },
    { Id: '44', Name: 'Media' },
    { Id: '45', Name: 'Metal' },
    { Id: '46', Name: 'Not for Profit' },	
    { Id: '47', Name: 'Packaging' },
    { Id: '48', Name: 'Paper and Print' },
    { Id: '49', Name: 'Patient Care' },
    { Id: '50', Name: 'Pharmaceutical' },
    { Id: '51', Name: 'Plastics and Rubber' },
    { Id: '52', Name: 'Printing' },	
    { Id: '53', Name: 'Property Management' },
    { Id: '54', Name: 'Recreation' },	
    { Id: '55', Name: 'Recycling/Waste' },
    { Id: '56', Name: 'Repair & Maintenance' },
    { Id: '57', Name: 'SoHo Small Office/Home Office' },
    { Id: '58', Name: 'Shipping' },
    { Id: '59', Name: 'Technology' },
    { Id: '60', Name: 'Telecommunication' },
    { Id: '61', Name: 'Textiles' },
    { Id: '62', Name: 'Transportation' },
    { Id: '63', Name: 'Utilities' },
    { Id: '64', Name: 'University' },
    { Id: '65', Name: 'Other' },
    { Id: '66', Name: 'Windows and Glass' }
  ],
  sicCodeSection: [
    { Id: '1', Name: 'Section A - Agriculture, Forestry and Fishing' },
    { Id: '2', Name: 'Section B - Mining and Quarrying' },
    { Id: '3', Name: 'Section C - Manufacturing' },
    { Id: '4', Name: 'Section D - Electricity, Gas, Steam and Airconditioning Supply' },
    { Id: '5', Name: 'Section E: Water Supply; Sewerage, Waste Management and Remediation Activities' },
    { Id: '6', Name: 'Section F: Construction' },
    { Id: '7', Name: 'Section G: Wholesale and Retail Trade; Repair Of Motor Vehicles and Motorcycles' },
    { Id: '8', Name: 'Section H: Transportation and Storage' },
    { Id: '9', Name: 'Section I: Accommodation and Food Service Activities' },
    { Id: '10', Name: 'Section J: Information and Communication' },
    { Id: '11', Name: 'Section K: Financial and Insurance Activities' },
    { Id: '12', Name: 'Section L: Real Estate Activities' },
    { Id: '13', Name: 'Section M: Professional, Scientific and Technical Activities' },
    { Id: '14', Name: 'Section N: Administrative and Support Service Activities' },
    { Id: '15', Name: 'Section O: Public Administration and Defence; Compulsory Social Security' },
    { Id: '16', Name: 'Section P: Education' },
    { Id: '17', Name: 'Section Q: Human Health and Social Work Activities' },
    { Id: '18', Name: 'Section R: Arts, Entertainment and Recreation' },
    { Id: '19', Name: 'Section S: Other Service Activities' },
    { Id: '20', Name: 'Section T: Activities Of Households As Employers; Undifferentiated Goods- and Services-Producing Activities Of Households For Own Use' },
    { Id: '21', Name: 'Section U: Activities Of Extraterritorial Organisations and Bodies' }
  ],
  sicSectionA: [
    { Id: '1', Name: 'Division 01 - Crop and Animal Production, Hunting and Related Activities' },
    { Id: '2', Name: 'Division 02 - Forestry and Logging' },
    { Id: '3', Name: 'Division 03 - Fishing and Aquaculture' }
    
  ],
  sicSectionB: [
    { Id: '5', Name: 'Division 05 - Mining of Coal and Lignite' },
    { Id: '6', Name: 'Division 06 - Extraction of Crude Petroleum and Natural Gas' },
    { Id: '7', Name: 'Division 07 - Mining of Metal Ores' },
    { Id: '8', Name: 'Division 08 - Other Mining and Quarrying' },
    { Id: '9', Name: 'Division 09 - Mining Support Service Activities' }
  ],
  sicSectionC: [
    { Id: '10', Name: 'Division 10: Manufacture of food products' },
    { Id: '11', Name: 'Division 11: Manufacture of beverages' },
    { Id: '12', Name: 'Division 12: Manufacture of tobacco products' },
    { Id: '13', Name: 'Division 13: Manufacture of textiles' },
    { Id: '14', Name: 'Division 14: Manufacture of wearing apparel' },
    { Id: '15', Name: 'Division 15: Manufacture of leather and related products' },
    { Id: '16', Name: 'Division 16: Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials' },
    { Id: '17', Name: 'Division 17: Manufacture of paper and paper products' },
    { Id: '18', Name: 'Division 18: Printing and reproduction of recorded media' },
    { Id: '19', Name: 'Division 19: Manufacture of coke and refined petroleum products' },
    { Id: '20', Name: 'Division 20: Manufacture of chemicals and chemical products' },
    { Id: '21', Name: 'Division 21: Manufacture of basic pharmaceutical products and pharmaceutical preparations' },
    { Id: '22', Name: 'Division 22: Manufacture of rubber and plastic products' },
    { Id: '23', Name: 'Division 23: Manufacture of other non-metallic mineral products' },
    { Id: '24', Name: 'Division 24: Manufacture of basic metals' },
    { Id: '25', Name: 'Division 25: Manufacture of fabricated metal products, except machinery and equipment' },
    { Id: '26', Name: 'Division 26: Manufacture of computer, electronic and optical products' },
    { Id: '27', Name: 'Division 27: Manufacture of electrical equipment' },
    { Id: '28', Name: 'Division 28: Manufacture of machinery and equipment n.e.c.' },
    { Id: '29', Name: 'Division 29: Manufacture of motor vehicles, trailers and semi-trailers' },
    { Id: '30', Name: 'Division 30: Manufacture of other transport equipment' },
    { Id: '31', Name: 'Division 31: Manufacture of furniture' },
    { Id: '32', Name: 'Division 32: Other manufacturing' },
    { Id: '33', Name: 'Division 33: Repair and installation of machinery and equipment' }
  ],
  sicSectionD: [
    { Id: '35', Name: 'Division 35: Electricity, gas, steam and air conditioning supply' }
  ],
  sicSectionE: [
    { Id: '36', Name: 'Division 36: Water collection, treatment and supply' },
    { Id: '37', Name: 'Division 37: Sewerage' },
    { Id: '38', Name: 'Division 38: Waste collection, treatment and disposal activities; materials recovery' },
    { Id: '39', Name: 'Division 39: Remediation activities and other waste management services' }
  ],
  sicSectionF: [
    { Id: '41', Name: 'Division 41: Construction of buildings' },
    { Id: '42', Name: 'Division 42: Civil engineering' },
    { Id: '43', Name: 'Division 43: Specialised construction activities' }
  ],
  sicSectionG: [
    { Id: '45', Name: 'Division 45: Wholesale and retail trade and repair of motor vehicles and motorcycles' },
    { Id: '46', Name: 'Division 46: Wholesale trade, except of motor vehicles and motorcycles' },
    { Id: '47', Name: 'Division 47: Retail trade, except of motor vehicles and motorcycles' }
  ], 
  sicSectionH: [
    { Id: '49', Name: 'Division 49: Land transport and transport via pipelines' },
    { Id: '50', Name: 'Division 50: Water transport' },
    { Id: '51', Name: 'Division 51: Air transport' },
    { Id: '52', Name: 'Division 52: Warehousing and support activities for transportation' },
    { Id: '53', Name: 'Division 53: Postal and courier activities' }
  ], 
  sicSectionI: [
    { Id: '55', Name: 'Division 55: Accommodation' },
    { Id: '56', Name: 'Division 56: Food and beverage service activities' }
  ],
  sicSectionJ: [
    { Id: '58', Name: 'Division 58: Publishing activities' },
    { Id: '59', Name: 'Division 59: Motion picture, video and television programme production, sound recording and music publishing activities' },
    { Id: '60', Name: 'Division 60: Programming and broadcasting activities' },
    { Id: '61', Name: 'Division 61: Telecommunications' },
    { Id: '62', Name: 'Division 62: Computer programming, consultancy and related activities' },
    { Id: '63', Name: 'Division 63: Information service activities' }
  ], 
  sicSectionK: [
    { Id: '64', Name: 'Division 64: Financial service activities, except insurance and pension funding' },
    { Id: '65', Name: 'Division 65: Insurance, reinsurance and pension funding, except compulsory social security' },
    { Id: '66', Name: 'Division 66: Activities auxiliary to financial services and insurance activities' }
  ], 
  sicSectionL: [
    { Id: '68', Name: 'Division 68: Real estate activities' }
  ], 
  sicSectionM: [
    { Id: '69', Name: 'Division 69: Legal and accounting activities' },
    { Id: '70', Name: 'Division 70: Activities of head offices; management consultancy activities' },
    { Id: '71', Name: 'Division 71: Architectural and engineering activities; technical testing and analysis' },
    { Id: '72', Name: 'Division 72: Scientific research and development' },
    { Id: '73', Name: 'Division 73: Advertising and market research' },
    { Id: '74', Name: 'Division 74: Other professional, scientific and technical activities' },
    { Id: '75', Name: 'Division 75: Veterinary activities' }
  ], 
  sicSectionN: [
    { Id: '77', Name: 'Division 77: Rental and leasing activities' },
    { Id: '78', Name: 'Division 78: Employment activities' },
    { Id: '79', Name: 'Division 79: Travel agency, tour operator and other reservation service and related activities' },
    { Id: '80', Name: 'Division 80: Security and investigation activities' },
    { Id: '81', Name: 'Division 81: Services to buildings and landscape activities' },
    { Id: '82', Name: 'Division 82: Office administrative, office support and other business support activities' }
  ],
  sicSectionO: [
    { Id: '84', Name: 'Division 84: Public administration and defence; compulsory social security' }
  ],
  sicSectionP: [
    { Id: '85', Name: 'Division 85: Education' }
  ],
  sicSectionQ: [
    { Id: '86', Name: 'Division 86: Human health activities' },
    { Id: '87', Name: 'Division 87: Residential care activities' },
    { Id: '88', Name: 'Division 88: Social work activities without accommodation' }
  ],
  sicSectionR: [
    { Id: '90', Name: 'Division 90: Creative, arts and entertainment activities' },
    { Id: '91', Name: 'Division 91: Libraries, archives, museums and other cultural activities' },
    { Id: '92', Name: 'Division 92: Gambling and betting activities' },
    { Id: '93', Name: 'Division 93: Sports activities and amusement and recreation activities' }
  ],
  sicSectionS: [
    { Id: '94', Name: 'Division 94: Activities of membership organisations' },
    { Id: '95', Name: 'Division 95: Repair of computers and personal and household goods' },
    { Id: '96', Name: 'Division 96: Other personal service activities' }
  ],
  sicSectionT: [
    { Id: '97', Name: 'Division 97: Activities of households as employers of domestic personnel' },
    { Id: '98', Name: 'Division 98: Undifferentiated goods- and services-producing activities of private households for own use' }
  ],
  sicSectionU: [
    { Id: '99', Name: 'Division 99: Activities of extraterritorial organisations and bodies' }
  ],
  contactType: [
    { Id: '1', Name: 'Customer and Prospect' },
    { Id: '2', Name: 'EIC and T-Mac' },
    { Id: '3', Name: 'Suppliers(Others)' },
    { Id: '4', Name: 'Utility Supplier Contact' }
  ],
  salutation: [
    { Id: '1', Name: 'Mr.' },
    { Id: '2', Name: 'Mrs.' },
    { Id: '3', Name: 'Ms.' },
    { Id: '4', Name: 'Dr.' },
    { Id: '5', Name: 'Prof.' }
  ],
  currencyType: [
    { Id: '1', Name: 'GBP - British Pound' },
    { Id: '2', Name: 'USD - U.S. Dollar' },
    { Id: '3', Name: 'AUD - Australian Dollar' },
    { Id: '4', Name: 'CAD - Canadian Dollar' }
  ],
  inActiveReason: [
    { Id: '1', Name: 'GDBR - Do Not Contact Request' },
    { Id: '2', Name: 'Moved company' },
    { Id: '3', Name: 'Moved roles within the company' },
    { Id: '4', Name: 'Retired' },
  ],
  industry: [
    { Id: '1', Name: 'Agriculture' },
    { Id: '2', Name: 'Apparel' },
    { Id: '3', Name: 'Banking' },
    { Id: '4', Name: 'Biotechnology' },
    { Id: '5', Name: 'Chemicals' },
    { Id: '6', Name: 'Communications' },
    { Id: '7', Name: 'Council/Local Authority' },
    { Id: '8', Name: 'Construction' },
    { Id: '9', Name: 'Consulting' },
    { Id: '10', Name: 'Education' },
    { Id: '11', Name: 'Electronics' },
    { Id: '12', Name: 'Energy' },
    { Id: '13', Name: 'Engineering' },
    { Id: '14', Name: 'Entertainment' },
    { Id: '15', Name: 'Environmental' },
    { Id: '16', Name: 'Facility Management' },
    { Id: '17', Name: 'Finance' },
    { Id: '18', Name: 'Food & Beverage' },
    { Id: '19', Name: 'Government' },
    { Id: '20', Name: 'Healthcare' },
    { Id: '21', Name: 'HoReCa or Hospitality' },
    { Id: '22', Name: 'Housing Association' },
    { Id: '23', Name: 'Insurance' },
    { Id: '24', Name: 'Machinery' },
    { Id: '25', Name: 'Manufacturing' },
    { Id: '26', Name: 'Media' },
    { Id: '27', Name: 'Not for Profit' },
    { Id: '28', Name: 'Property Management' },
    { Id: '29', Name: 'Recreation' },
    { Id: '30', Name: 'Repair & Maintenance' },
    { Id: '31', Name: 'SoHo Small Office/Home Office' },
    { Id: '32', Name: 'Shipping' },
    { Id: '33', Name: 'Technology' },
    { Id: '34', Name: 'Telecommunication' },
    { Id: '35', Name: 'Transportation' },
    { Id: '36', Name: 'Utilities' },
    { Id: '37', Name: 'Other' }
  ],
  leadSource: [
    { Id: '1', Name: 'Advertisement' },
    { Id: '2', Name: 'Employee Referral' },
    { Id: '3', Name: 'External Referral' },
    { Id: '4', Name: 'Partner' },
    { Id: '5', Name: 'Public Relations' },
    { Id: '6', Name: 'Seminar Internal' },
    { Id: '7', Name: 'Seminar Partner' },
    { Id: '8', Name: 'Trade Show' },
    { Id: '9', Name: 'Web' },
    { Id: '10', Name: 'Word of Mouth' },
    { Id: '11', Name: 'Other' },
    { Id: '12', Name: 'Purchased List' },
    { Id: '13', Name: 'Academics Show 2018 ExCel London' }
  ],
  gasMeterUnit: [
    { Id: '0.1', Name: 'NM3 - Tenths of Cubic Metres (0.1)' },
    { Id: '1', Name: 'M3 - Cubic Meters (1)' },
    { Id: '10', Name: 'TM3 - Tens of Cubic Metres (10)' },
    { Id: '100', Name: 'HM3 - Hundreds of Cubic Metres (100)' },
    { Id: '0.028317', Name: 'OCUF - One Cubic Foot (0.028317)' },
    { Id: '0.28317', Name: 'TCUF - Tens of Cubic Feet (0.28317)' },
    { Id: '2.8317', Name: 'HCUF - Hundreds of Cubic Feet (2.8317)' },
    { Id: '28.3', Name: 'MCUF - Thousands of Cubic Feet (28.3)' }
  ],

  gasSettlementClass: [
    { Id: 'C1', Name: 'Class1' },
    { Id: 'C2', Name: 'Class2' },
    { Id: 'C3', Name: 'Class3' },
    { Id: 'C4', Name: 'Class4' },
    { Id: 'C5', Name: 'Class5' } 
  ],

  standingChargePeriod: [
    { Id: 1, Name: 'Daily' },
    { Id: 2, Name: 'Monthly' },
    { Id: 3, Name: 'Quarterly' }
  ],

  fixedChargePeriod: [
    { Id: 1, Name: 'Daily' },
    { Id: 2, Name: 'Monthly' },
    { Id: 3, Name: 'Quarterly' }
  ],

  otherChargingPeriod: [
    { Id: 1, Name: 'Daily' },
    { Id: 2, Name: 'Monthly' }
  ],
  
  waterChargingPeriod: [
    { Id: 1, Name: 'Daily' },
    { Id: 2, Name: 'Monthly' },
    { Id: 3, Name: 'Quarterly' },
    { Id: 4, Name: 'Yearly' },
    { Id: 5, Name: 'RV' }
  ],

  ReconciliationChargingPeriod: [  
    { Id: 3, Name: 'Quarterly' },
    { Id: 4, Name: 'Annually' },
    { Id: 5, Name: 'LastMonth' },
    { Id: 6, Name: 'Two Months Earlier' },
    
  ],

  gasTariffsContractType: [
    { Id: 1, Name: 'Fixed' },
    { Id: 2, Name: 'Flex' }
  ],

  gasTariffsEnergyType: [
    { Id: 1, Name: 'Natural gas' },
    { Id: 2, Name: 'True Green (aerobic digestion etc)' },
    { Id: 3, Name: 'Carbon offset' }
  ],

  elecTariffsContractType: [
    { Id: 1, Name: 'Out of contract' },
    { Id: 2, Name: 'Public Procured Contract' },
    { Id: 3, Name: 'Split Commission' },
    { Id: 4, Name: 'Full Commission' }
  ],

  elecHHTariffsContractType: [
    { Id: 1, Name: 'Energy Only' },
    { Id: 2, Name: 'Fully Delivered' }
  ],

  elecTariffsEnergyType: [
    { Id: 1, Name: 'Renewable energy' },
    { Id: 2, Name: 'Nuclear' },
    { Id: 3, Name: 'Fossil fuels' }
  ],

  paymentType: [
    { Id: 1, Name: 'BACS' },
    { Id: 2, Name: 'DD' },
    { Id: 3, Name: 'Cheque' }
  ],

  capacityChargeType: [
    { Id: 1, Name: 'Daily' },
    { Id: 2, Name: 'Monthly' }
  ],
  

  paymentDuration: [
    { Id: 4, Name: '10 Days' },
    { Id: 1, Name: '14 Days' },
    { Id: 2, Name: '21 Days' },
    { Id: 3, Name: '28 Days' }
    
  ],

  invoiceType: [
    { Id: 'CE', Name: 'Calculated Estimate' },
    { Id: 'CR', Name: 'Customer Reading' },
    { Id: 'FR', Name: 'Final Reading' },
    { Id: 'SE', Name: 'Supplier Estimate' },
    { Id: 'SR', Name: 'Supplier Reading' },
    { Id: 'HH', Name: 'HH Reading' }
  ],
  waterinvoiceType: [
    { Id: 'CE', Name: 'Calculated Estimate' },
    { Id: 'CR', Name: 'Customer Reading' },
    { Id: 'FR', Name: 'Final Reading' },
    { Id: 'SE', Name: 'Supplier Estimate' },
    { Id: 'SR', Name: 'Supplier Reading' }
  ],
  allInvoiceType: [
    { Id: 'CE', Name: 'Calculated Estimate' },
    { Id: 'CR', Name: 'Customer Reading' },
    { Id: 'FR', Name: 'Final Reading' },
    { Id: 'SE', Name: 'Supplier Estimate' },
    { Id: 'SR', Name: 'Supplier Reading' },
    { Id: 'HH', Name: 'HH Reading' },
    { Id: 'CN', Name: 'Credit Note' },
  ],

  BSUosMonth: [ 
    { Id: 1, Name: 'Previous Month' },
    { Id: 2, Name: 'Current Month' }
  ],

  FITChargeType: [ 
    { Id: 1, Name: 'Quarterly' },
    { Id: 2, Name: 'Yearly' }
  ],

  unitClass: [ 
    { Id: 1, Name: 'Unit Rate' },
    { Id: 7, Name: 'Unit Rate (Uplift to GSP)' },
    { Id: 8, Name: 'Unit Rate (Uplift to NBP)' },
    { Id: 9, Name: 'Consumption (Uplift to NBP)' },
    { Id: 2, Name: 'Distribution Loss' },
    { Id: 3, Name: 'Trasmission Loss' },
    { Id: 4, Name: 'Exclude From Total' },
    { Id: 5, Name: 'Exclude From Total (Uplift to GSP)' },
    { Id: 6, Name: 'Exclude From Total (Uplift to NBP)' }
  ],

  channelType: [ 
    { Id: 1, Name: 'Electricity (kWh)' },
    { Id: 2, Name: 'Electricity (kVAh)' },
    { Id: 3, Name: 'Electricity (kVArh)' },
    { Id: 4, Name: 'Electricity kVArh Lead' },
    { Id: 5, Name: 'Electricity (kVAh) Calculated' }
  ],

  channelStatus: [ 
    { Id: 1, Name: 'Active' },
    { Id: 2, Name: 'Inactive' }
  ],

  HHDataTemplate: [
    { Id: 1, Name: 'HavenPower' },
    { Id: 2, Name: 'Smartest' },
    { Id: 3, Name: 'Bryt' },
    { Id: 4, Name: 'EOn' },
    { Id: 5, Name: 'Hudson' },
    { Id: 6, Name: 'Npower' },
    { Id: 7, Name: 'Siemens' },
    { Id: 8, Name: 'Shell' },
    { Id: 9, Name: 'TGP' },
    { Id: 10, Name: 'Generic-HHD' },
    { Id: 11, Name: 'Generic-Reactive' },
    { Id: 12, Name: 'EDF' },
    { Id: 13, Name: 'Smartest Portal' },
    { Id: 14, Name: 'Smartest Portal Reactive' },
    { Id: 15, Name: 'Haven Power HHD (CSV)' },
    { Id: 16, Name: 'Generic AI RI RE' },
  ],
  ReportType: [
    { Id: 1, Name: 'elec' },
    { Id: 2, Name: 'gas' },
    { Id: 3, Name: 'hhelec' },
    { Id: 4, Name: 'water' }
  ],

  FeedbackStatus: [
    { Id: 1, Name: 'Open' },
    { Id: 2, Name: 'Pending' },
    { Id: 3, Name: 'Hold' },
    { Id: 4, Name: 'Solved' },
    { Id: 5, Name: 'Closed' },
  ],

  AccountStatus: [
    { Id: 1, Name: 'Active' },
    { Id: 2, Name: 'Closed' },
  ],

  UtilType: [
    { Id: 1, Name: 'elec' },
    { Id: 2, Name: 'gas' },
    { Id: 3, Name: 'hhelec' }
  ],

  EBType: [
    { Id: 1, Name: 'SSE_Electric' },
    { Id: 2, Name: 'BritishGas_Electric' },
    { Id: 3, Name: 'EDF_Electric' },
    { Id: 4, Name: 'NPower_Electric' },
    { Id: 5, Name: 'Smartest_Electric' },
    { Id: 6, Name: 'TotalGas_Electric' },
    { Id: 7, Name: 'Opus_Electric' },
    { Id: 8, Name: 'Opus_Gas' },
    { Id: 9, Name: 'TotalGas_Gas' },
    { Id: 10, Name: 'Gazprom_Gas' },
    { Id: 11, Name: 'SSE_Gas' },
    { Id: 12, Name: 'Corona_Gas' },
    { Id: 13, Name: 'BritishGas_Gas' },
    { Id: 14, Name: 'NPower_HHElectric' },
    { Id: 15, Name: 'Smartest_HHElectric' },
    { Id: 16, Name: 'Haven_HHElectric' },
    { Id: 17, Name: 'TotalGas_HHElectric' },
    { Id: 18, Name: 'EDF_HHElectric' },
    { Id: 19, Name: 'NPower_Junifer_Electric' },
    { Id: 20, Name: 'ShellUK_EHHlectric' },
    { Id: 21, Name: 'TotalGasAdditionalChrg_Gas' },
    { Id: 22, Name: 'Haven_Electric' },
  ],

  GenericReportType: [
    { Id: 1, Name: 'sr183' },
    { Id: 2, Name: 'sr160A' },
    { Id: 3, Name: 'sr168' },
    { Id: 4, Name: 'hhdata' },
    { Id: 5, Name: 'sr164' },
    { Id: 6, Name: 'tr301' },
    { Id: 7, Name: 'sr529' },
    { Id: 8, Name: 'sr130' },
    { Id: 9, Name: 'sr181' },
    { Id: 10, Name: 'sg184' },
    { Id: 11, Name: 'gr202' },
    { Id: 12, Name: 'sr104e' },
    { Id: 13, Name: 'sr104g' },
    { Id: 14, Name: 'tr335' },
    { Id: 15, Name: 'sd368' },
    { Id: 16, Name: 'sd369' },
    { Id: 17, Name: 'billhistory' },
    { Id: 18, Name: 'exporttariff' },
    { Id: 20, Name: 'UtilDataReport' },
    { Id: 21, Name: 'RecentContracts' },
    { Id: 22, Name: 'SiteList' },
    { Id: 23, Name: 'CCL' },    
    { Id: 24, Name: 'Registrations' },    
    { Id: 25, Name: 'Contract' },
    { Id: 26, Name: 'NoticeLetter' },    
    { Id: 27, Name: 'BusinessClosed' },
    { Id: 28, Name: 'AccountClass' },    
    { Id: 29, Name: 'SG180' },
    { Id: 30, Name: 'SG181' },
    { Id: 31, Name: 'SG182' },
    { Id: 32, Name: 'SG185' },
    { Id: 33, Name: 'UtilDataReport_Gas' },
    { Id: 34, Name: 'AccuralReport_Elec' },
    { Id: 35, Name: 'AccuralReport_Gas' },
    { Id: 36, Name: 'AccountOwner' },
    { Id: 37, Name: 'AllContracts' }, 
    { Id: 38, Name: 'Commission' },
    { Id: 39, Name: 'SupplierAnalysis' },
    { Id: 40, Name: 'Mon_ApprovalReport' },
    { Id: 41, Name: 'SpecialReport' } ,
    { Id: 42, Name: 'DataCollection' },
    { Id: 43, Name: 'Mon_ApprovalReport_consumption' },
    { Id: 44, Name: 'ConsumptionReport_CNB' },
    { Id: 45, Name: 'CapacityMarket' },
    { Id: 46, Name: 'sr104w' },
    { Id: 47, Name: 'sr529w' },
    { Id: 48, Name: 'WaterTender' },
    { Id: 49, Name: 'sr160A_Gas' },
  ],

  FileType: [
    { Id: 1, Name: 'XLSX' },
    { Id: 2, Name: 'PDF' },
    { Id: 3, Name: 'CSV' }
  ],

  AnalysisByType: [
    { Id: 1, Name: 'Meter' },
    { Id: 2, Name: 'Account (Electric Detail)' }
  ],

  GraphByType: [
    { Id: 1, Name: 'Day With Highest Demand' },
    { Id: 2, Name: 'Day With Lowest Demand' },
    { Id: 3, Name: 'Day With Highest Consumption' },
    { Id: 4, Name: 'Day With Lowest Consumption' }
  ],

  ReconciliationFrequency: [
    { Id: 1, Name: 'Annually' },
    { Id: 2, Name: 'Quarterly' },
    { Id: 3, Name: 'Two Months Earlier' },
    { Id: 4, Name: 'Last Month' }
  ],

  
  InvoiceTypes: [
    { Id : '1', Name: 'All the Invoices' },
    { Id : '2', Name: 'Non Approval Report Invoices' }
  ],

  TariffTypes: [
    { Id : '0', Name: 'All Tariff Type' },
    { Id : '1', Name: 'Non Half-Hourly Electricity' },
    { Id : '2', Name: 'Half-hourly Electricity' }
  ],
  AccountGroupTariffTypes: [
    { Id : 0, Name: 'All Tariff Type' },
    { Id : 1, Name: 'NHH - Non Half-Hourly Electricity' },
    { Id : 2, Name: 'HH - Half-hourly Electricity' },
    { Id : 3, Name: 'HHWD - Half Hourly Without Data' },
    { Id : 4, Name: 'Gas' },
    { Id : 5, Name: 'Water' }
  ],

  ReconciliationMonths: [
    { Id: 1, Name: 'Jan' },
    { Id: 2, Name: 'Feb' },
    { Id: 3, Name: 'Mar' },
    { Id: 4, Name: 'Apr' },
    { Id: 5, Name: 'May' },
    { Id: 6, Name: 'Jun' },
    { Id: 7, Name: 'Jul' },
    { Id: 8, Name: 'Aug' },
    { Id: 9, Name: 'Sep' },
    { Id: 10, Name: 'Oct' },
    { Id: 11, Name: 'Nov' },
    { Id: 12, Name: 'Dec' },  
  ],

  ReconciliationTypes:[
    { Id: 1, Name: 'RO' },
    { id: 2, Name:'BSUoS' },
    { id: 3, Name:'FIT' },
    { Id: 4, Name: 'TUoS' },
    { Id: 5, Name: 'CM Settlement Levy' },
    { Id: 6, Name: 'CFD Supplier Obligation' },
    { Id: 7, Name: 'CM Supplier Obligation' },
  ],

  ReconciliationQuarterlyMonths: [
    { Id: 1, Name: 'Jan' }, 
    { Id: 4, Name: 'Apr' },  
    { Id: 7, Name: 'Jul' }, 
    { Id: 10, Name: 'Oct' },
  
  ],

  getGasMeterType:[
    { Id: 1 , Name: 'Non-Daily' },
    { Id: 2 , Name: 'Daily' },
  ],

  getElecMeterType:[
    { Id: 1 , Name: 'Non Half Hourly' },
    { Id: 2 , Name: 'Half Hourly' },
    { Id: 3 , Name: 'UnMeter' },
  ],
  getUtilityType:[
    { Id: 1 , Name: 'Import' },
    { Id: 2 , Name: 'Export' },
  ],
  
  getElecSupplyVoltage :[
    { Id: 1 , Name: 'LV - 415V' },
    { Id: 2 , Name: 'HV -11kV' },
    { Id: 3 , Name: 'EHV - 33kV' },
  ],

  TR301ReportType:[
    { Id: 1 , Name: 'Contract Signed' },
    { Id: 2 , Name: 'Contract Started' },
  ],
  getTransporter:[
    { Id:1, Name: 'National Transmission System' },
    { Id:2, Name: 'Independent Gas Transporter' },
  ],
  accountStatus:[
    { Id:1, Name: 'Active' },
    { Id:2, Name: 'Closed' },
  ],

  //Water Types.
  WaterTypes:[
    { Id: 1, Name: 'All' },
    { Id: 2, Name: 'Sewerage' },
    { Id: 3, Name: 'Water' },
  ],

  WaterBands:[
    { Id: 1, Name: 'Band A' },
    { Id: 2, Name: 'Band B' },
    { Id: 3, Name: 'Band C' },
    { Id: 4, Name: 'Band D' },
    { Id: 5, Name: 'Band E' },
    { Id: 6, Name: 'Band F' },
    { Id: 7, Name: 'Band G' },
    { Id: 8, Name: 'Band U' },
  ],

  WaterRules:[
    { Id: 1, Name: 'Greater than equal to 90 days' },
    { Id: 2, Name: 'Less than equal to 90 days' },
    { Id: 3, Name: 'Greater than equal to 180 days' },
    { Id: 4, Name: 'Usage > 50 Metric Cubes' },
  ],

  WaterUOM:[
    { Id: 1, Name: 'M3' },
    { Id: 2, Name: 'Days' },
  ],

  WaterMeterSizes:[
    { Id: 1, Name: '0 mm' },
    { Id: 2, Name: '13 mm' },
    { Id: 3, Name: '15 mm' },    
    { Id: 4, Name: '20 mm' },
    { Id: 5, Name: '25 mm' },
    { Id: 6, Name: '32 mm' },
    { Id: 7, Name: '40 mm' },
    { Id: 8, Name: '50 mm' },    
    { Id: 9, Name: '65 mm' },    
    { Id: 10, Name: '80 mm' },    
    { Id: 11, Name: '100 mm' },    
    { Id: 12, Name: '125 mm' },    
    { Id: 13, Name: '150 mm' },    
    { Id: 14, Name: '200 mm' },    
    { Id: 15, Name: '250 mm' },    
    { Id: 16, Name: '300 mm' },    
    { Id: 17, Name: '350 mm' },    
    { Id: 18, Name: '400 mm' },    
  ],

  WaterSupplyType:[
    { Id: 1, Name: 'Water' },
    { Id: 2, Name: 'Sewerage' },
  ],

  WaterSupplierName:[
    { Id: 1, Name: 'ANH Anglian Water' },
    { Id: 2, Name: 'WSH Dŵr Cymru' },
    { Id: 3, Name: 'NES Northumbrian Water' },
    { Id: 4, Name: 'SVE Severn Trent Water' },
    { Id: 5, Name: 'SWB South West Water' },
  ],

  WaterMeterUnit:[
    { Id: 1, Name: 'M3 - Cubic Meters' },
  ],

  WaterMeterType:[
    { Id: 1, Name: 'Domestic' },
    { Id: 2, Name: 'Commercial' },
  ],

  WaterConnectionType:[
    { Id: 1, Name: 'Domestic' },
    { Id: 2, Name: 'Commercial' },
  ],

  WaterMeterStatus:[
    { Id: 1, Name: 'Active', Code: 'Active' },
    { Id: 2, Name: 'Non-Active', Code: 'Non-Active' },
  ],

  WaterReadType: [
    { Id: 1, Name: 'Estimated' },
    { Id: 2, Name: 'Actual' },
  ],

  WaterRetailType: [
    { Id: 1, Name: 'Retail' },
    { Id: 2, Name: 'Wholesale' },
  ],

  //01 Jul 2022. Mop Dc / Da changes. Senthil Raja
  MeterPhase:[
    { Id: 1, Name: 'Single' },
    { Id: 2, Name: 'Three' },
  ],

  MeterType:[
    { Id: 1, Name: 'Whole Current' },
    { Id: 2, Name: 'CT Metering' },
  ],

  MeterCOP:[
    { Id: 1, Name: 'COP 3' },
    { Id: 2, Name: 'COP 5' },
    { Id: 3, Name: 'COP 10' },
  ],

  MeterContractType:[
    { Id: 1, Name: 'Lease' },
    { Id: 2, Name: 'Purchase' },
    { Id: 3, Name: 'Adoption' },
  ],

  MeterDCDAContractType:[
    { Id: 4, Name: 'Contracted' },
    { Id: 5, Name: 'Nominated' },
  ],

  MopdcdaFilters:[
    { Id: 1, Name: 'All' },
    { Id: 2, Name: 'Mop Bundle' },
    { Id: 3, Name: 'Mop Only' },
    { Id: 4, Name: 'DC/DA Only' },
  ],

  TemplateType: [
    { Id: 1, Name: 'Account' },
    { Id: 2, Name: 'Site' },
    { Id: 3, Name: 'HHDetail' },
    { Id: 4, Name: 'NHHDetail' },
    { Id: 5, Name: 'GASDetail' },
    { Id: 6, Name: 'HHTarrif' },
    { Id: 7, Name: 'NHHTarrif' },
    { Id: 8, Name: 'GASTarrif' }
  ],
  
}
