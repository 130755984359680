import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class ElecHHBillResolver extends BaseResolver {
  constructor () {
    super('elecHHBill')
  }

  resolve (item) {

    const bill = item.Bill || {}
    const calculated = item.Calculated || {}

    return {
      Bill: {
        BillId: bill.BillId,
        AccountId: bill.AccountId ,
        SiteId: bill.SiteId,
        ElecDetailId: bill.ElecDetailId,
        SupplierId: bill.SupplierId,
        TaxPointDate: bill.TaxPointDate ? new Date(bill.TaxPointDate) : null,
        InvoiceNo: bill.InvoiceNo,
        MaxDemand: bill.MaxDemand,
        FromDate: bill.FromDate ? new Date(bill.FromDate) : null,
        ToDate: bill.ToDate ? new Date(bill.ToDate) : null,
        InvoiceType: bill.InvoiceType,
        AAHEDC: bill.AAHEDC,
        BSUoS: bill.BSUoS,
        TUoS: bill.TUoS,
        FIT: bill.FIT,
        RO: bill.RO,
        Standing: bill.Standing,
        CCL: bill.CCL,
        AvailabilityCharge: bill.AvailabilityCharge,
        ExAvailabilityCharge: bill.ExAvailabilityCharge,
        DUoSRed: bill.DUoSRed,
        DUoSSuperRed: bill.DUoSSuperRed,
        DUoSAmber: bill.DUoSAmber,
        DUoSGreen: bill.DUoSGreen,
        DUoSFixed: bill.DUoSFixed,
        DUoSCapacity: bill.DUoSCapacity,
        DUoSExcessCapacity: bill.DUoSExcessCapacity,
        DUoSRP: bill.DUoSRP,
        CfdInterim: bill.CfdInterim,
        CfdOperationalLevy: bill.CfdOperationalLevy,
        CMSelttementLevy: bill.CMSelttementLevy,
        CMObligationLevy: bill.CMObligationLevy,
        FixedCMSettlementLevy: bill.FixedCMSettlementLevy,
        FixedCMObligationLevy: bill.FixedCMObligationLevy,
        Other: bill.Other,
        Net: bill.Net,
        Vat1: bill.Vat1,
        Vat2: bill.Vat2 || 0,
        Total: bill.Total,
        Adjustment: bill.Adjustment || 0,
        TotaInvoicedkWh: bill.TotaInvoicedkWh || 0,
        Approved: bill.Approved,
        ApprovedBy: bill.ApprovedBy,
        ApprovedDate: bill.ApprovedDate,
        ReportId: bill.ReportId,
        ReportBy: bill.ReportBy,
        ReportDate: bill.ReportDate,
        ZendeskId: bill.ZendeskId,
        ZendeskStatus: bill.ZendeskStatus,
        CreditNoteId: bill.CreditNoteId,
        CreatedBy: bill.CreatedBy,
        CreatedDate: bill.CreatedDate,
        UpdatedBy: bill.UpdatedBy,
        UpdatedDate: bill.UpdatedDate,
        ApplyEMRCharges: item.ApplyEMRCharges,
        ShowDUoS: item.ShowDUoS,
        ShowSuperRed: item.ShowSuperRed,
        ShowDUoSLoss: item.ShowDUoSLoss,
        ShowTUoS: item.ShowTUoS,
        ShowTUoSLoss: item.ShowTUoSLoss,
        ShowFIT: item.ShowFIT,
        ShowRO: item.ShowRO,
        ShowAAHEDC: item.ShowAAHEDC,
        ShowBSUoS: item.ShowBSUoS,
        ShowAvailability: item.ShowAvailability,
        ShowExAvailability: item.ShowExAvailability,
        SupplierName: bill.SupplierName,
        
        UnitCharges: (item.UnitCharges || []).map(charge => {
          return {
            Id: charge.Id,
            ChargeId: charge.ChargeId,
            BillId: charge.BillId,
            Code: charge.Code,
            ChargeName: charge.ChargeName,
            Price: charge.Price,
            Calculated: charge.Calculated,
            kWh: charge.kWh
          }
        }),
        FixedCharges: (item.FixedCharges || []).map(charge => {
          return {
            Id: charge.Id,
            ChargeId: charge.ChargeId,
            BillId: charge.BillId,
            Code: charge.Code,
            ChargeName: charge.ChargeName,
            Price: charge.Price,
            Calculated: charge.Calculated,
            kWh: calculated.BillDays
          }
        }),
        IsHistoricData: bill.IsOldSystemData ? bill.IsOldSystemData : false,
        IsMigrated: bill.IsMigrated ? bill.IsMigrated : false,
        ReconRO: bill.ReconRO,
        ShowROReconcilation: item.ShowROReconcilation,
        ReconTUoS: bill.ReconTUoS,
        ShowTUoSReconcilation: item.ShowTUoSReconcilation,
        ReconFIT: bill.ReconFIT,
        ShowFITReconcilation: item.ShowFITReconcilation,
        ShowCMSSLReconciliation: item.ShowCMSSLReconciliation,
        ShowCMSELReconciliation: item.ShowCMSELReconciliation,
        ReconCMSEL:bill.ReconCMSEL,
        ReconCMSUL:bill.ReconCMSUL,
        ReconBSUoS: bill.ReconBSUoS,
        ShowBSUoSReconcilation: item.ShowBSUoSReconcilation,
        ReconCFD:bill.ReconCFD,
        ShowCFDReconcilation: true
      },
      Calculated: {
        BillId: calculated.BillId,
        TariffId: calculated.TariffId,
        AAHEDC: calculated.AAHEDC,
        BSUoS: calculated.BSUoS,
        TUoS: calculated.TUoS,
        FIT: calculated.FIT,
        RO: calculated.RO,
        Standing: calculated.Standing,
        CCL: calculated.CCL,
        AvailabilityCharge: calculated.AvailabilityCharge,
        ExAvailabilityCharge: calculated.ExAvailabilityCharge,
        DUoSRed: calculated.DUoSRed,
        DUoSSuperRed: calculated.DUoSSuperRed,
        DUoSAmber: calculated.DUoSAmber,
        DUoSGreen: calculated.DUoSGreen,
        DUoSFixed: calculated.DUoSFixed,
        DUoSCapacity: calculated.DUoSCapacity,
        DUoSExcessCapacity: calculated.DUoSExcessCapacity,
        DUoSRP: calculated.DUoSRP,
        FixedCMSettlementLevy: calculated.FixedCMSettlementLevy,
        FixedCMObligationLevy: calculated.FixedCMObligationLevy,
        CfdInterim: calculated.CfdInterim,
        CfdOperationalLevy: calculated.CfdOperationalLevy,
        CMSelttementLevy: calculated.CMSelttementLevy,
        CMObligationLevy: calculated.CMObligationLevy,
        Other: calculated.Other,
        Net: (calculated.Net + bill.Adjustment).toFixed(2),
        Vat1: calculated.Vat1,
        Vat2: calculated.Vat2 || 0,
        Total: (calculated.Total+ bill.Adjustment).toFixed(2),
        kWh: calculated.kWh,
        Ratio: calculated.Ratio ? calculated.Ratio : util.getRatio(calculated.Net+ bill.Adjustment , bill.Net),
        NetRatio: util.getRatio( calculated.Net+ bill.Adjustment,bill.Net ),
        BillDays: calculated.BillDays,
        TotalkWh: calculated.TotalkWh,
        FITkWh: calculated.FITkWh,
        CCLkWh: calculated.CCLkWh,
        CCLDiscount: calculated.CCLDiscount,
        DUoSRedkWh: calculated.DUoSRedkWh,
        DUoSSuperRedkWh: calculated.DUoSSuperRedkWh,
        DUoSAmberkWh: calculated.DUoSAmberkWh,
        DUoSGreenkWh: calculated.DUoSGreenkWh,
        DUoSRPkWh: calculated.DUoSRPkWh,
        DUoSCapkVa: calculated.DUoSCapkVa,
        DUoSExCapkVa: calculated.DUoSExCapkVa,
        CMSettlementLevykWh: calculated.CMSettlementLevykWh,
        CMObligationLevykWh: calculated.CMObligationLevykWh,
        CFDInterimkWh: calculated.CFDInterimkWh,
        CfdOperationalLevykWh: calculated.CfdOperationalLevykWh,
        ReconRO: calculated.ReconRO,
        ReconTUoS: calculated.ReconTUoS,
        ReconFIT: calculated.ReconFIT,   
        ReconCMSEL:calculated.ReconCMSEL,
        ReconCMSUL:calculated.ReconCMSUL,
        ReconBSUoS: calculated.ReconBSUoS,
        ReconCFD:calculated.ReconCFD
      },
    }
  }
}
