import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class ElecBillResolver extends BaseResolver {
  constructor () {
    super('elecBill')
  }

  resolve (item) {

    const bill = item.Bill || {}
    const calculated = item.Calculated || {}

    return {
      Bill: {
        BillId: bill.BillId,
        AccountId: bill.AccountId ,
        SiteId: bill.SiteId,
        ElecDetailId: bill.ElecDetailId,
        SupplierId: bill.SupplierId,
        TaxPointDate: bill.TaxPointDate ? new Date(bill.TaxPointDate) : null,
        InvoiceNo: bill.InvoiceNo,
        FromDate: bill.FromDate ? new Date(bill.FromDate) : null,
        ToDate: bill.ToDate ? new Date(bill.ToDate) : null,
        InvoiceType: bill.InvoiceType,
        Day: bill.Day,
        Night: bill.Night,
        Evening: bill.Evening,
        Other: bill.Other,
        DecJanPeak: bill.DecJanPeak,
        DecJanOffPeak: bill.DecJanOffPeak,
        NovFebPeak: bill.NovFebPeak,
        NovFebOffPeak: bill.NovFebOffPeak,
        DecToFebPeak: bill.DecToFebPeak,
        DecToFebOffPeak: bill.DecToFebOffPeak,
        Availability: bill.Availability,
        FIT: bill.FIT,
        Standing: bill.Standing,
        CCL: bill.CCL,
        RP: bill.RP,
        OtherCharge: bill.OtherCharge,
        Net: bill.Net,
        Vat1: bill.Vat1,
        Vat2: bill.Vat2 || 0,
        Total: bill.Total,
        Adjustment: bill.Adjustment || 0,
        TotaInvoicedkWh: bill.TotaInvoicedkWh || 0,
        Approved: bill.Approved,
        ApprovedBy: bill.ApprovedBy,
        ApprovedDate: bill.ApprovedDate,
        ReportId: bill.ReportId,
        ReportBy: bill.ReportBy,
        ReportDate: bill.ReportDate,
        ZendeskId: bill.ZendeskId,
        ZendeskStatus: bill.ZendeskStatus,
        CreditNoteId: bill.CreditNoteId,
        CreatedBy: bill.CreatedBy,
        CreatedDate: bill.CreatedDate,
        UpdatedBy: bill.UpdatedBy,
        UpdatedDate: bill.UpdatedDate,
        ShowDay: bill.ShowDay,
        ShowNight: bill.ShowNight,
        ShowEvening: bill.ShowEvening,
        ShowOther: bill.ShowOther,
        ShowDecJanPeak: bill.ShowDecJanPeak,
        ShowDecJanOffPeak: bill.ShowDecJanOffPeak,
        ShowNovFebPeak: bill.ShowNovFebPeak,
        ShowNovFebOffPeak: bill.ShowNovFebOffPeak,
        ShowDecToFebPeak: bill.ShowDecToFebPeak,
        ShowDecToFebOffPeak: bill.ShowDecToFebOffPeak,
        ShowAvailability: bill.ShowAvailability,
        ShowFIT: bill.ShowFIT,
        AccountName: bill.AccountName,
        SiteRef: bill.SiteRef,
        SupplierName: bill.SupplierName,
        SitePostCode: bill.SitePostCode,
        SiteAddress: bill.SiteAddress,
        IsHistoricData: bill.IsOldSystemData ? bill.IsOldSystemData : false,
        IsMigrated: bill.IsMigrated ? bill.IsMigrated : false,
        CfdInterim: bill.CfdInterim,
        CfdOperationalLevy: bill.CfdOperationalLevy,
        CMSelttementLevy: bill.CMSelttementLevy,
        CMObligationLevy: bill.CMObligationLevy,
        FixedCMSettlementLevy: bill.FixedCMSettlementLevy,
        FixedCMObligationLevy: bill.FixedCMObligationLevy,
        ApplyEMRCharges: item.ApplyEMRCharges,
        ApplyHHUnitCharges: item.ApplyHHUnitCharges,
        ReconRO: bill.ReconRO,
        ShowROReconcilation: item.ShowROReconcilation,
        ReconTUoS: bill.ReconTUoS,
        ShowTUoSReconcilation: item.ShowTUoSReconcilation,
        ReconFIT: bill.ReconFIT,
        ShowFITReconcilation: item.ShowFITReconcilation,
        ShowCMSSLReconciliation: item.ShowCMSSLReconciliation,
        ShowCMSELReconciliation: item.ShowCMSELReconciliation,
        ReconCMSEL:bill.ReconCMSEL,
        ReconCMSUL:bill.ReconCMSUL,
        ReconBSUoS: bill.ReconBSUoS,
        ShowBSUoSReconcilation: item.ShowBSUoSReconcilation,
        ReconCFD:bill.ReconCFD,
        ShowCFDReconcilation: true
      },
      Calculated: {
        BillId: calculated.BillId,
        Day: calculated.Day,
        Night: calculated.Night,
        Evening: calculated.Evening,
        Other: calculated.Other,
        DecJanPeak: calculated.DecJanPeak,
        DecJanOffPeak: calculated.DecJanOffPeak,
        NovFebPeak: calculated.NovFebPeak,
        NovFebOffPeak: calculated.NovFebOffPeak,
        DecToFebPeak: calculated.DecToFebPeak,
        DecToFebOffPeak: calculated.DecToFebOffPeak,
        Availability: calculated.Availability,
        FIT: calculated.FIT,
        Standing: calculated.Standing,
        CCL: calculated.CCL,
        RP: calculated.RP,
        OtherCharge: calculated.OtherCharge,
        Net: (calculated.Net+ bill.Adjustment).toFixed(2),
        Vat1: calculated.Vat1,
        Vat2: calculated.Vat2,
        Total: (calculated.Total+ bill.Adjustment).toFixed(2),
        kWh: calculated.kWh,
        FixedCMSettlementLevy: calculated.FixedCMSettlementLevy,
        FixedCMObligationLevy: calculated.FixedCMObligationLevy,
        CfdInterim: calculated.CfdInterim,
        CfdOperationalLevy: calculated.CfdOperationalLevy,
        CMSelttementLevy: calculated.CMSelttementLevy,
        CMObligationLevy: calculated.CMObligationLevy,
        CMSettlementLevykWh: calculated.CMSettlementLevykWh,
        CMObligationLevykWh: calculated.CMObligationLevykWh,
        CFDInterimkWh: calculated.CFDInterimkWh,
        CfdOperationalLevykWh: calculated.CfdOperationalLevykWh,
        ReconRO: calculated.ReconRO,
        ReconTUoS: calculated.ReconTUoS,
        ReconFIT: calculated.ReconFIT,   
        ReconCMSEL:calculated.ReconCMSEL,
        ReconCMSUL:calculated.ReconCMSUL,
        ReconBSUoS: calculated.ReconBSUoS,
        ReconCFD:calculated.ReconCFD,
        Ratio: calculated.Ratio ? calculated.Ratio : util.getRatio(calculated.Net+ bill.Adjustment , bill.Net)
      },
      FixedCharges: (item.FACCharges || []).filter(x => x.Type === 1).map(charge => {
        return {
          Id: charge.Id,
          BillId: charge.BillId,
          Code: charge.Code,
          ChargeName: charge.ChargeName,
          Price: charge.Price,
          Calculated: charge.Calculated
        }
      }),
      ConsumptionCharges: (item.FACCharges || []).filter(x => x.Type === 2).map(charge => {
        return {
          Id: charge.Id,
          BillId: charge.BillId,
          Code: charge.Code,
          ChargeName: charge.ChargeName,
          Price: charge.Price,
          Calculated: charge.Calculated
        }
      }),
      UnitCharges: (item.UnitCharges || []).map(charge => {
        return {
          Id: charge.Id,
          ChargeId: charge.ChargeId,
          BillId: charge.BillId,
          Code: charge.Code,
          ChargeName: charge.ChargeName,
          Price: charge.Price,
          Calculated: charge.Calculated,
          kWh: charge.kWh
        }
      }),
      MeterReadings: (item.Readings || []).map(r => {
        return {
          Mpan: r.Mpan,
          MpanId: r.MpanId,
          MSN:r.MSN,
          ShowDay: (r.Periods || []).some(x => x.ShowDay == true),
          ShowNight: (r.Periods || []).some(x => x.ShowNight == true),
          ShowEvening: (r.Periods || []).some(x => x.ShowEvening == true),
          ShowOther: (r.Periods || []).some(x => x.ShowOther == true),
          ShowDecJanPeak: (r.Periods || []).some(x => x.ShowDecJanPeak == true),
          ShowDecJanOffPeak: (r.Periods || []).some(x => x.ShowDecJanOffPeak == true),
          ShowNovFebPeak: (r.Periods || []).some(x => x.ShowNovFebPeak == true),
          ShowNovFebOffPeak: (r.Periods || []).some(x => x.ShowNovFebOffPeak == true),
          ShowDecToFebPeak: (r.Periods || []).some(x => x.ShowDecToFebPeak == true),
          ShowDecToFebOffPeak: (r.Periods || []).some(x => x.ShowDecToFebOffPeak == true),
          Periods: (r.Periods ||[]).map(p => {
            return {
              BillTariffId: p.BillTariffId,
              TariffId: p.TariffId,
              StartDate:new Date(p.StartDate),
              EndDate: new Date(p.EndDate),
              AvailabilityUnits: p.AvailabilityUnits,
              ShowAvailability: p.ShowAvailability,
              ShowDay: p.ShowDay,
              ShowNight: p.ShowNight,
              ShowEvening: p.ShowEvening,
              ShowOther: p.ShowOther,
              ShowDecJanPeak: p.ShowDecJanPeak,
              ShowDecJanOffPeak: p.ShowDecJanOffPeak,
              ShowNovFebPeak: p.ShowNovFebPeak,
              ShowNovFebOffPeak: p.ShowNovFebOffPeak,
              ShowDecToFebPeak: p.ShowDecToFebPeak,
              ShowDecToFebOffPeak: p.ShowDecToFebOffPeak,
              MeterReading: (p.MeterReading || []).map(reading => {
                return {
                  Id: reading.Id,
                  StartDate: new Date(reading.StartDate),
                  EndDate: new Date(reading.EndDate),
                  DayFrom: reading.DayFrom,
                  DayTo: reading.DayTo,
                  NightFrom: reading.NightFrom,
                  NightTo: reading.NightTo,
                  EveningFrom: reading.EveningFrom,
                  EveningTo: reading.EveningTo,
                  OtherFrom: reading.OtherFrom,
                  OtherTo: reading.OtherTo,
                  DecJanPeakFrom: reading.DecJanPeakFrom,
                  DecJanPeakTo: reading.DecJanPeakTo,
                  DecJanOffPeakFrom: reading.DecJanOffPeakFrom,
                  DecJanOffPeakTo: reading.DecJanOffPeakTo,
                  NovFebPeakFrom: reading.NovFebPeakFrom,
                  NovFebPeakTo: reading.NovFebPeakTo,
                  NovFebOffPeakFrom: reading.NovFebOffPeakFrom,
                  NovFebOffPeakTo: reading.NovFebOffPeakTo,
                  DecToFebPeakFrom: reading.DecToFebPeakFrom,
                  DecToFebPeakTo: reading.DecToFebPeakTo,
                  DecToFebOffPeakFrom: reading.DecToFebOffPeakFrom,
                  DecToFebOffPeakTo: reading.DecToFebOffPeakTo,
                  ReadType: reading.ReadType, 
                  disabledDates: {
                    from: new Date(p.EndDate),
                    to: new Date(p.StartDate)
                  }
                }
              })
            }
          })
        }
      })
    }
  }
}
