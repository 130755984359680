export const mutations = {
  auth: {
    login: 'login',
    logout: 'logout'
  },
  validation: {
    getSite: 'getSite',
    gSuppliers: 'gSuppliers',
    eSuppliers: 'eSuppliers',
    retailWaterSuppliers: 'retailWaterSuppliers',
    wholesaleWaterSuppliers: 'wholesaleWaterSuppliers',
    oppContacts:'oppContacts',
    oppContactRoles: 'oppContactRoles',
    waterFixedCharges: 'waterFixedCharges',
    waterUnitCharges:'waterUnitCharges',
    waterFixedChargesUnMetered:'waterFixedChargesUnMetered',
    waterUnitClass:'waterUnitClass',
    gi: 'gi',
    ei: 'ei',
    newei: 'newei',
    elecOtherCharges: 'elecOtherCharges',
    gasOtherCharges: 'gasOtherCharges',
    unitCharges: 'unitCharges',
    supplyType: 'supplyType',
    transporterType: 'transporterType',
    accountOwners: 'accountOwners',
    accountTypes: 'accountTypes',
    LDZExitZone: 'LDZExitZone',
    LDZExitZoneDescription: 'LDZExitZoneDescription',
    wtdtid: 'wtdtid',
    msndetails: 'msndetails',
    wtrCharges: 'wtrCharges',
    WaterMeterMake: 'WaterMeterMake',
    WaterMeterSize: 'WaterMeterSize',
    WaterPropertySize: 'WaterPropertySize',
    WaterMeasureType:'WaterMeasureType',
    multipleWaterMeter:'multipleWaterMeter'
  },
  alert: {
    showAlert: 'showAlert',
    closeAlert: 'closeAlert'
  },
  customer: {
    getAllCustomers: 'getAllCustomers',
    getAllSites: 'getAllSites',
    getAllInActiveSites: 'getAllInActiveSites',
    getBothActiveInActiveSites: 'getBothActiveInActiveSites'
  }
}