import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class SiteResolver extends BaseResolver {
  constructor () {
    super('site')
  }

  resolve (item) {
    return {
      id: item.Id,
      accountId: item.AccountId,
      locationId: item.LocationId,
      locationName: `Site ${item.LocationId}`,
      siteRef: item.SiteRef,
      clientSiteRef: item.ClientSiteRef,
      address: util.getAddress(item.BillingStreet, item.BillingCity, item.BillingState, item.BillingPostcode, item.BillingCountry),
      scheme: item.Scheme,
      schemeCode: item.SchemeCode,
      expenseCode: item.ExpenseCode,
      vatType: item.VatType,
      billingStreet: item.BillingStreet,
      billingCity: item.BillingCity,
      billingState: item.BillingState,
      billingCountry: item.BillingCountry,
      billingPostcode: item.BillingPostcode,
      startDate: item.StartDate,
      endDate: item.EndDate,
      createdBy: item.CreatedBy,
      createdDate: item.CreatedDate,
      lastModifiedBy: item.UpdatedBy,
      lastModifiedDate: item.UpdatedDate,
      accountName: item.Account_Name,
      accountOwner: item.Account_Owner,
      contacts: item.Contacts,
      siteOccupancy: item.SiteOccupancy,
      utilType: item.UtilType,
      status: item.Status,
      statusText: item.Status ? 'Active' : 'In Active',
      siteStatus: (item.SiteStatus || []).map(status => {
        return {
          id: status.Id,
          startDate: status.StartDate,
          endDate: status.Date,
          status: status.Status,
          statusText: status.Status ? 'Active' : 'In Active',
          updatedBy: status.UpdatedBy,
          updatedDate: status.UpdatedDate
        }
      })
    }
  }
}
